import BaseCrud from "../../../_reduxBase/BaseCrud";
import BaseSlice from "../../../_reduxBase/BaseSlice";
import BaseActions from "../../../_reduxBase/BaseActions";
import baseInitialEntity from "../../../_reduxBase/BaseIntialEntity";
import { customServerErrorMessageFormatter, errorMessageFormatter, sortByStandard } from "../../../_commons/Utils";
import Axios from "axios";
import moment from "moment";



export const reducerInfo = {
  name: "allInvoiceMaster",
  idFieldName: "id",
  initialEnitity: {
    ...baseInitialEntity,
    organizationMSTId: 0,
    projectMSTId: 0,
    fromDate: "",
    toDate: "",
    biilingFrom: "",
    invoiceNumber: "",
    address: "",
    PO: "",
    id: 0,
    expenses: 0,
    "invoiceMSTId": null,
    "description": "",
  },
};



const convertDateFormat = (data) => {
  const tmpEntities = data.map(x => {
    
    return { ...x,
      invoiceDate: moment(x.invoiceDate).format('YYYY-MM-DD'),
      invoiceDueDate: moment(x.invoiceDueDate).format('YYYY-MM-DD'),
      invoiceFromDate: moment(x.invoiceFromDate).format('YYYY-MM-DD'),
      invoiceToDate: moment(x.invoiceToDate).format('YYYY-MM-DD'),
      settlementDate: moment(x.settlementDate).format('YYYY-MM-DD'),
  }
  })
  // console.log("this is test", tmpEntities)
  return tmpEntities ?? [];

}


class Crud extends BaseCrud {
  getInvoice(data) {
    return Axios.post(
      process.env.REACT_APP_API_URL + "invoiceMST/getAllInvoice/", data
    );
  }
  exportCsv(data) {
    return Axios.post(process.env.REACT_APP_API_URL + 'invoiceMST/reportsCSV/allInvoice' , data, {
      responseType: 'blob'
    })}

}

class Actions extends BaseActions {


  exportCsv = (data) => (dispatch) => {
    dispatch(this.actions.startCall({ callType: this.callTypes.action }));
    return this.requestFromServer
      .exportCsv(data)
      .then((res) => {
        if (res?.status === 200) {
          dispatch(this.actions.stopCall({ callType: this.callTypes.action }));
          return Promise.resolve(res.data);
        } else {
          const err = {
            userMessage: errorMessageFormatter(res.data),
            error: res.data,
          };
          dispatch(
            this.actions.catchError({
              error: err,
              callType: this.callTypes.action,
            })
          );
          return Promise.reject(err);
        }
      })
      .catch((error) => {
        const err = {
          userMessage: errorMessageFormatter(error.response.data),
          error: error,
        };
        dispatch(
          this.actions.catchError({
            error: err,
            callType: this.callTypes.action,
          })
        );
        return Promise.reject(err);
      });
  };

  
  getInvoice = (data) => dispatch => {
        dispatch(this.actions.startCall({ callType: this.callTypes.list }))
        return this.requestFromServer
          .getInvoice(data, message => dispatch(this.actions.loadingMessage({ message })))
          .then(res => {
            if (res?.data.status) {
              const entities = res.data.data ? convertDateFormat(res.data.data) : []

              dispatch(this.actions.recordsFetched({ totalCount: 0, entities: entities ?? {} }))

            } else {
              const err = {
                userMessage: errorMessageFormatter(res.data),
                error: res.data
              }
              dispatch(this.actions.catchError({ error: err, callType: this.callTypes.list }))
              
            }
          })
          .catch(error => {
    
            const err = {
              userMessage: "Can't get data "+ error?.userMessage ,
              error: error
            }
            dispatch(this.actions.catchError({ error: err, callType: this.callTypes.list }))
        
          })
      }
}

export const allInvoiceMasterCrud = new Crud("");
export const allInvoiceMasterSlice = new BaseSlice(
  reducerInfo.name,
  reducerInfo.idFieldName
);
export const allInvoiceMasterActions = new Actions(
    allInvoiceMasterCrud,
    allInvoiceMasterSlice
);
