import BaseCrud from "../../../_reduxBase/BaseCrud"
import BaseSlice from "../../../_reduxBase/BaseSlice"
import BaseActions from "../../../_reduxBase/BaseActions"
import baseInitialEntity from "../../../_reduxBase/BaseIntialEntity"
import Axios from "axios"
import { errorMessageFormatter ,customServerErrorMessageFormatter} from "../../../_commons/Utils"

export const reducerInfo = {
  name: "termsConditionMaster",
  idFieldName: 'id',
  initialEnitity: {
    ...baseInitialEntity,
    "toCountry": "",
    "fromCountry": "",
    "tax":0,
    "countryMSTId": 0,
  }
}

class Crud extends BaseCrud {
  updateMultiple(values) {
    return Axios.put(process.env.REACT_APP_API_URL  + "/update/multiple/", values)
  }

  getPaginated() {
    'this is calling'
    // return Axios.get("http://103.211.219.28:8080/ticketingappapitest-uat/termsConditionMST/all/");
    return Axios.get(process.env.REACT_APP_API_URL +  this.API_MASTER );
  }
}

class Action extends BaseActions {

  updateMultiple(values) {
    return new Promise(async (res1, reje) => {
      try {
        const res = await this.requestFromServer.updateMultiple(values)
        if (!res?.data?.status) {
          const err = {
            userMessage: errorMessageFormatter(res?.data),
            error: res?.data
          }
          return reje({ ...res?.data, userMessage: res?.data?.message ?? "Error in Saving Data"})
        }
        else {
          res1({ res })
        }
      } catch (error) {
        reje(error)
      }
    })
  }

  getPaginated = () => dispatch => {
    dispatch(this.actions.startCall({ callType: this.callTypes.list }))
    return this.requestFromServer
      .getPaginated()
      .then(res => {
        const entities = res.data.data ?? []
        const totalCount = entities.length ?? 0
        const totalElements = res.data.data.totalElements ?? 0
        dispatch(this.actions.recordsFetched({ totalCount, entities }))
        if(totalElements > 10) {
          dispatch(this.getAfterPaginated(totalElements))
        }
      })
      .catch(error => {
        const err = {
          userMessage: "Can't get all paginated " + this.reducerName,
          error: error
        }
        dispatch(this.actions.catchError({ error: err, callType: this.callTypes.list }))
      })
  }

  getAfterPaginated = (pageSize) => dispatch => {
    dispatch(this.actions.startCall({ callType: this.callTypes.action }))
    return this.requestFromServer
      .getPaginated(pageSize)
      .then(res => {
        const entities = res.data.data.content ?? []
        const totalCount = entities.length ?? 0
        dispatch(this.actions.recordsFetched({ totalCount, entities }))
        dispatch(this.actions.stopCall({ callType: this.callTypes.action }))
      })
      .catch(error => {
        const err = {
          userMessage: "Can't get all " + this.reducerName,
          error: error
        }
        dispatch(this.actions.catchError({ error: err, callType: this.callTypes.list }))
        dispatch(this.actions.stopCall({ callType: this.callTypes.action }))
      })
  }

}

export const termsConditionMasterCrud = new Crud("termsConditionMST")
export const termsConditionMasterSlice = new BaseSlice(reducerInfo.name, reducerInfo.idFieldName)
export const termsConditionMasterrActions = new Action(termsConditionMasterCrud, termsConditionMasterSlice)
