import BaseCrud from "../../../_reduxBase/BaseCrud";
import BaseSlice from "../../../_reduxBase/BaseSlice";
import BaseActions from "../../../_reduxBase/BaseActions";
import baseInitialEntity from "../../../_reduxBase/BaseIntialEntity";
import {
  customServerErrorMessageFormatter,
  errorMessageFormatter,
  sortByStandard,
} from "../../../_commons/Utils";
import Axios from "axios";
import moment from "moment";

export const reducerInfo = {
  name: "invoiceDrafted",
  idFieldName: "id",
  initialEnitity: {
    ...baseInitialEntity,
    expenses: 0,
    invoiceMSTId: null,
    billingFromEntityId: 0,
    dateOfProject: "",
    description: "",
    qty: null,
    tax: null,
    total: null,
    itemPrice: null,
  },
};
const convertDateFormat = (data) => {
  const tmpEntities = data.map(x => {
    return { ...x,
       invoiceDueDate: moment(x.invoiceDueDate).format('YYYY-MM-DD'),
      invoiceCreationDate: moment(x.invoiceCreationDate).format('YYYY-MM-DD')
  }
  })
  // console.log("this is test", tmpEntities)
  return tmpEntities ?? [];

}
class Crud extends BaseCrud {
  postToEndpoint(endpoint, data) {
    return Axios.post(`${process.env.REACT_APP_API_URL}${endpoint}`, data);
  }
  getInvoiceData() {
    return Axios.get(process.env.REACT_APP_API_URL  +  this.API_MASTER  +"/getDraftInvoice/");
  }
  deleteInvoice(id) {
    return Axios.get(process.env.REACT_APP_API_URL  +  this.API_MASTER  +"/invoiceMST/delete/"  + id);
  }

  getToInvoice(data) {
    return Axios.get(process.env.REACT_APP_API_URL + 'invoiceMST/previewInvoice/' + data, {
      responseType: 'blob'
    })
  }
  getToInvoiceCsv(data) {
    return Axios.get(process.env.REACT_APP_API_URL + 'invoiceMST/previewReport/' + data, {
      responseType: 'blob'
    })
  }
}

class Actions extends BaseActions {

  previewInvoice = (data) => (dispatch) => {
    dispatch(this.actions.startCall({ callType: this.callTypes.action }));
    return this.requestFromServer
      .postToInvoice("invoiceMST/generatePdf/", data)
      .then((res) => {
        if (res?.status === 200) {
          return Promise.resolve(res.data);
        } else {
          const err = {
            userMessage: errorMessageFormatter(res.data),
            error: res.data,
          };
          dispatch(
            this.actions.catchError({
              error: err,
              callType: this.callTypes.action,
            })
          );
          return Promise.reject(err);
        }
      })
      .catch((error) => {
        const err = {
          userMessage: errorMessageFormatter(error.response.data),
          error: error,
        };
        dispatch(
          this.actions.catchError({
            error: err,
            callType: this.callTypes.action,
          })
        );
        return Promise.reject(err);
      });
  };

  getToInvoice = (data) => (dispatch) => {
    dispatch(this.actions.startCall({ callType: this.callTypes.action }));
    return this.requestFromServer
      .getToInvoice(data)
      .then((res) => {
        if (res?.status === 200) {
          return Promise.resolve(res.data);
        } else {
          const err = {
            userMessage: errorMessageFormatter(res.data),
            error: res.data,
          };
          dispatch(
            this.actions.catchError({
              error: err,
              callType: this.callTypes.action,
            })
          );
          return Promise.reject(err);
        }
      })
      .catch((error) => {
        const err = {
          userMessage: errorMessageFormatter(error.response.data),
          error: error,
        };
        dispatch(
          this.actions.catchError({
            error: err,
            callType: this.callTypes.action,
          })
        );
        return Promise.reject(err);
      });
  };

  getToInvoiceCsv = (data) => (dispatch) => {
    dispatch(this.actions.startCall({ callType: this.callTypes.action }));
    return this.requestFromServer
      .getToInvoiceCsv(data)
      .then((res) => {
        if (res?.status === 200) {
          return Promise.resolve(res.data);
        } else {
          const err = {
            userMessage: errorMessageFormatter(res.data),
            error: res.data,
          };
          dispatch(
            this.actions.catchError({
              error: err,
              callType: this.callTypes.action,
            })
          );
          return Promise.reject(err);
        }
      })
      .catch((error) => {
        const err = {
          userMessage: errorMessageFormatter(error.response.data),
          error: error,
        };
        dispatch(
          this.actions.catchError({
            error: err,
            callType: this.callTypes.action,
          })
        );
        return Promise.reject(err);
      });
  };


  getInvoiceData = () => dispatch => {
    dispatch(this.actions.startCall({ callType: this.callTypes.list }))
    return this.requestFromServer
      .getInvoiceData()
      .then(res => {
        const entities = res.data.data ? convertDateFormat(res.data.data) : []
        const totalCount = entities.length ?? 0
       dispatch(this.actions.recordsFetched({ totalCount, entities }))
      })
      .catch(error => {
        const err = {
          userMessage: "Can't get all data" + this.reducerName,
          error: error
        }
        console.log(err)
        dispatch(this.actions.catchError({ error: err, callType: this.callTypes.list }))
      })
  }

  // deleteInvoiceData = (id) => dispatch => {
  //   dispatch(this.actions.startCall({ callType: this.callTypes.list }))
  //   return this.requestFromServer
  //     .getInvoiceData(id)
  //     .then(res => {
  //       const entities = res.data.data ?? []
  //       const totalCount = entities.length ?? 0
  //       const totalElements = res.data.data.totalElements ?? 0
  //       dispatch(this.actions.recordsFetched({ totalCount, entities }))
  //     })
  //     .catch(error => {
  //       const err = {
  //         userMessage: "Can't get all data" + this.reducerName,
  //         error: error
  //       }
  //       dispatch(this.actions.catchError({ error: err, callType: this.callTypes.list }))
  //     })
  // }


 
}

export const draftedInvoiceMasterTableCrud = new Crud("invoiceMST");
export const draftedInvoiceMasterTableSlice = new BaseSlice(
  reducerInfo.name,
  reducerInfo.idFieldName
);
export const draftedInvoiceMasterTableActions = new Actions(
  draftedInvoiceMasterTableCrud,
  draftedInvoiceMasterTableSlice
);
