import BaseCrud from "../../../_reduxBase/BaseCrud"
import BaseSlice from "../../../_reduxBase/BaseSlice"
import BaseActions from "../../../_reduxBase/BaseActions"
import baseInitialEntity from "../../../_reduxBase/BaseIntialEntity"
import Axios from "axios"
import { errorMessageFormatter, sortArrayByDate, sortByStandard } from "../../../_commons/Utils"
import moment from "moment";

export const reducerInfo = {
  name: "taskDisputeNote",
  idFieldName: 'id',
  initialEnitity: {
    ...baseInitialEntity,
    taskMSTId: null,
    disputeFlag: true,
    flagNote: true,
  }
}

class Crud extends BaseCrud {
    getByTaskMSTId(id) {
        return Axios.get(process.env.REACT_APP_API_URL + this.API_MASTER + "/byTaskMST/" + id);
    }
}

const sortByPlannedDate = (data) => {

    // const timezone = moment.tz.guess(true)
  
    let userTimezone = moment.tz.guess(true);


    const tmpEntities = data.map(x => {
      // let tmpTimezone = timezone
      // if (x?.latitude && x?.longitude) {
      //   tmpTimezone = tz_lookup(x.latitude, x.longitude)
      // }
      
      // return { ...x, planDateTime: moment.tz(x.planDateTime, "utc").tz(tmpTimezone).format('YYYY-MM-DD HH:mm') }
   
      return { ...x, modifiedOn:moment
        .tz(x.modifiedOn, "utc")
        .tz(userTimezone)
        .format("YYYY-MM-DD HH:mm:ss") }
    })
  
    return sortArrayByDate(tmpEntities, "planDateTime") ?? []
  
  }

class Actions extends BaseActions {

    getByTaskMSTId = (id) => dispatch => {
        dispatch(this.actions.recordsFetched({ totalCount: 0, entities: [] }))
        dispatch(this.actions.startCall({ callType: this.callTypes.list }))

        return this.requestFromServer
            .getByTaskMSTId(id)
            .then(res => {
                if (res?.data?.status) {
                    const entities = res.data.data ? sortByPlannedDate(res.data.data) : []
                    // const entities = sortByStandard(res.data.data ?? [])
                    const totalCount = entities.length ?? 0
                    dispatch(this.actions.recordsFetched({ totalCount, entities }))
                } else {
                    const err = {
                        userMessage: errorMessageFormatter(res.data),
                        error: res.data
                    }
                    dispatch(this.actions.catchError({ error: err, callType: this.callTypes.list }))
                }
            })
            .catch(error => {
                const err = {
                    userMessage: "Can't get by task master id " + this.reducerName,
                    error: error
                }
                dispatch(this.actions.catchError({ error: err, callType: this.callTypes.list }))
            })
    }

    uploadAttachment = (id, file, path = '') => dispatch => {
        dispatch(this.actions.startCall({ callType: this.callTypes.action }));
        return this.requestFromServer
            .uploadFile(id, file, path = '')
            .then(response => {
                dispatch(this.actions.recordUpdated({ response }));
                return Promise.resolve(response)
            })
            .catch(error => {
                const err = {
                    userMessage: `Can't upload file ` + this.reducerName,
                    error: error
                }
                dispatch(this.actions.catchError({ error: err, callType: this.callTypes.action }));
                return Promise.reject(err)
            });
    };
}

export const TaskDisputeNoteCrud = new Crud("taskDisputeNote")
export const TaskDisputeNoteSlice = new BaseSlice(reducerInfo.name, reducerInfo.idFieldName)
export const TaskDisputeNoteActions = new Actions(TaskDisputeNoteCrud, TaskDisputeNoteSlice)
