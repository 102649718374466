import BaseCrud from "../../../_reduxBase/BaseCrud";
import BaseSlice from "../../../_reduxBase/BaseSlice";
import BaseActions from "../../../_reduxBase/BaseActions";
import baseInitialEntity from "../../../_reduxBase/BaseIntialEntity";
import { customServerErrorMessageFormatter, errorMessageFormatter, sortByStandard } from "../../../_commons/Utils";
import Axios from "axios";

export const reducerInfo = {
  name: "invoiceMasterTable",
  idFieldName: "id",
  initialEnitity: {
    ...baseInitialEntity,
    "expenses":0,
    "invoiceMSTId": null,
    "billingFromEntityId":0,
    "dateOfProject":"",
    "description": "",
    "qty": null,
    "tax": null,
    "total": null,
    "itemPrice": null,
  },
};

class Crud extends BaseCrud {
    postToEndpoint(endpoint, data) {
        return Axios.post(`${process.env.REACT_APP_API_URL}${endpoint}`, data);
      }
}

class Actions extends BaseActions {
  


    // postToServer = (data) => (dispatch) => {
    //     dispatch(this.actions.startCall({ callType: this.callTypes.action }));
    
    //     return this.requestFromServer
    //       .postToEndpoint("invoiceMST/generateTable/", data)
    //       .then((res) => {
    //         if (res?.status === 200) {
    //           dispatch(this.actions.recordCreatedSaved({ entity:res.data.data }))
    //           return Promise.resolve(res.data.data);
    //         } else {
    //           const err = {
    //             userMessage: errorMessageFormatter(res.data),
    //             error: res.data,
    //           };
    //           dispatch(
    //             this.actions.catchError({
    //               error: err,
    //               callType: this.callTypes.action,
    //             })
    //           );
    //           return Promise.reject(err);
    //         }
    //       })
    //       .catch((error) => {
    //         const err = {
    //           userMessage: errorMessageFormatter(error.response.data),
    //           error: error,
    //         };
    //         dispatch(
    //           this.actions.catchError({
    //             error: err,
    //             callType: this.callTypes.action,
    //           })
    //         );
    //         return Promise.reject(err);
    //       });
    //   };
   


     postToServer = (data) => (dispatch) => {
    dispatch(this.actions.startCall({ callType: this.callTypes.action }));

    return this.requestFromServer
      .postToEndpoint("invoiceMST/generateTable/", data)
      .then((res) => {
        if (res?.data.status) {
          dispatch(this.actions.recordCreatedSaved({ entity:res.data.data }))
          return Promise.resolve(res.data.data);
        } else {
          const err = {
            userMessage: errorMessageFormatter(res.data),
            error: res.data,
          };
          dispatch(
            this.actions.catchError({
              error: err,
              callType: this.callTypes.action,
            })
          );
          return Promise.reject(err);
        }
      })
      .catch((error) => {
        if(!error?.userMessage) {
        const err = {
          userMessage: errorMessageFormatter(error.response),
          error: error,
        };
        dispatch(
          this.actions.catchError({
            error: err,
            callType: this.callTypes.action,
          })
        );
        } else {
          return Promise.reject(error)
      } 

      });

  };




 



 
}

export const invoiceMasterTableCrud = new Crud("invoiceMST/generateTable/");
export const invoiceMasterTableSlice = new BaseSlice(
  reducerInfo.name,
  reducerInfo.idFieldName
);
export const invoiceMasterTableActions = new Actions(
  invoiceMasterTableCrud,
  invoiceMasterTableSlice
);
