import BaseCrud from "../../../_reduxBase/BaseCrud";
import BaseSlice from "../../../_reduxBase/BaseSlice";
import BaseActions from "../../../_reduxBase/BaseActions";
import baseInitialEntity from "../../../_reduxBase/BaseIntialEntity";
import {
  customServerErrorMessageFormatter,
  errorMessageFormatter,
  sortByStandard,
} from "../../../_commons/Utils";
import Axios from "axios";
import moment from "moment";

let todayDate = moment().format("YYYY-MM-DD");
export const reducerInfo = {
  name: "dashboardallInvoiceMaster",
  idFieldName: "id",
  initialEnitity: {
    ...baseInitialEntity,
    organizationMSTId: 0,
    projectMSTId: [],
    fromDate: "",
    toDate: "",
    biilingFrom: "",
    billingFromDate: "",
    billingToDate: "",
    invoiceNumber: "",
    address: "",
    poNo: null,
    id: 0,
    invoiceCreationDates: todayDate,
    invoiceCreationDate: todayDate,
    // InvoiceCreationDate:todayDate ,
    invoiceDueDate: "",
    currencyMSTId: "",
    paymentTerms: "",
    vatGstTax: "",
    expenses: 0,
    invoiceMSTId: null,
    description: "",
  },
};

class Crud extends BaseCrud {
  // getInvoice() {
  //   return Axios.get(
  //     process.env.REACT_APP_BASE_API_URL + "invoiceMST/getInvoiceNumber/"
  //   );
  // }

  
  getAllInvoice(invoiceNumber) {
    return Axios.get(process.env.REACT_APP_API_URL + `invoiceMST/${invoiceNumber}/`);
  }

}

class Actions extends BaseActions {

    getAllInvoice = invoiceNumber => dispatch => {
        dispatch(this.actions.startCall({ callType: this.callTypes.list }))
        return this.requestFromServer
          .getAllInvoice(invoiceNumber)
          .then(res => {
            if (res?.data?.status) {
              let entities = res.data.data ?? []
                 
              entities = entities.reverse();

              const totalCount = entities.length ?? 0
              dispatch(this.actions.recordsFetched({ totalCount, entities }))
              return Promise.resolve(entities)
            } else {
              const err = {
                userMessage: errorMessageFormatter(res.data),
                error: res.data
              }
              dispatch(this.actions.catchError({ error: err, callType: this.callTypes.list }))
              return Promise.reject(err)
            }
          })
          .catch(error => {
            const err = {
              userMessage: "Can't get by invoiceNumber " + invoiceNumber,
              error: error
            }
            dispatch(this.actions.reIniState())
            dispatch(this.actions.catchError({ error: err, callType: this.callTypes.list }))
            return Promise.reject(err)
          })
    
      }


}

export const dashboardAllInvoiceMasterCrud = new Crud("allInvoiceMST");
export const dashboardAllMasterSlice = new BaseSlice(
  reducerInfo.name,
  reducerInfo.idFieldName
);
export const allInvoiceMasterActions = new Actions(
    dashboardAllInvoiceMasterCrud,
    dashboardAllMasterSlice
);
