import BaseCrud from "../../../_reduxBase/BaseCrud";
import BaseSlice from "../../../_reduxBase/BaseSlice";
import BaseActions from "../../../_reduxBase/BaseActions";
import baseInitialEntity from "../../../_reduxBase/BaseIntialEntity";
import Axios from "axios";

export const reducerInfo = {
  name: "referenceNo",
  idFieldName: "id",
  initialEnitity: {
    ...baseInitialEntity,
  },
};

class Crud extends BaseCrud {
  getRefrenceNo() {
    return Axios.get(
      process.env.REACT_APP_API_URL + this.API_MASTER + "/getReference1/"
    );
  }
}
class Action extends BaseActions {
  getRefrenceNo = () => (dispatch) => {
    dispatch(this.actions.startCall({ callType: this.callTypes.list }));

    return this.requestFromServer
      .getRefrenceNo()
      .then((res) => {
        const entities = res.data.data;
        const totalCount = entities.length ?? 0;
        dispatch(this.actions.recordsFetched({ totalCount, entities }));
      })
      .catch((error) => {
        const err = {
          userMessage: "Can't get all paginated " + this.reducerName,
          error: error,
        };
        dispatch(
          this.actions.catchError({ error: err, callType: this.callTypes.list })
        );
      });
  };
}

export const taskMasterRefNoCrud = new Crud("taskMST");
export const taskMasterRefNoSlice = new BaseSlice(
  reducerInfo.name,
  reducerInfo.idFieldName
);
export const taskMasterRefNoActions = new Action(
  taskMasterRefNoCrud,
  taskMasterRefNoSlice
);
