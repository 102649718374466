import BaseCrud from "../../../_reduxBase/BaseCrud";
import BaseSlice from "../../../_reduxBase/BaseSlice";
import BaseActions from "../../../_reduxBase/BaseActions";
import baseInitialEntity from "../../../_reduxBase/BaseIntialEntity";
import { customServerErrorMessageFormatter, errorMessageFormatter, sortArrayByDate, sortByStandard } from "../../../_commons/Utils";
import Axios from "axios";
import moment from "moment";



export const reducerInfo = {
  name: "unpaidMaster",
  idFieldName: "id",
  initialEnitity: {
    ...baseInitialEntity,
    organizationMSTId: 0,
    projectMSTId: 0,
    fromDate: "",
    toDate: "",
    biilingFrom: "",
    invoiceNumber: "",
    address: "",
    PO: "",
    id: 0,
    invoiceDueDate: "",
    currencyMSTId: "",
    paymentTerms: "",
    vatGstTax: "SHIFTED",
    expenses: 0,
    "invoiceMSTId": null,
    "description": "",
  },
};

const convertDateFormat = (data) => {
  const tmpEntities = data.map(x => {
    return { ...x,
       invoiceDueDate: moment(x.invoiceDueDate).format('YYYY-MM-DD'),
      invoiceCreationDate: moment(x.invoiceCreationDate).format('YYYY-MM-DD')
  }
  })
  // console.log("this is test", tmpEntities)
  return tmpEntities ?? [];

}

class Crud extends BaseCrud {
  getInvoice(data) {
    return Axios.post(
      process.env.REACT_APP_API_URL + "invoiceMST/getUnpaidInvoice/", data
    );
  }
  doSettlement(data) {
    return Axios.put(process.env.REACT_APP_API_URL + 'invoiceMST/settlement/', data)
  }

  exportCsv(data) {
    return Axios.post(process.env.REACT_APP_API_URL + 'invoiceMST/reportsCSV/unpaidInvoice' , data, {
      responseType: 'blob'
    })}

}



class Actions extends BaseActions {

  exportCsv = (data) => (dispatch) => {
    dispatch(this.actions.startCall({ callType: this.callTypes.action }));
    return this.requestFromServer
      .exportCsv(data)
      .then((res) => {
        if (res?.status === 200) {
          dispatch(this.actions.stopCall({ callType: this.callTypes.action }));
          return Promise.resolve(res.data);
        } else {
          const err = {
            userMessage: errorMessageFormatter(res.data),
            error: res.data,
          };
          dispatch(
            this.actions.catchError({
              error: err,
              callType: this.callTypes.action,
            })
          );
          return Promise.reject(err);
        }
      })
      .catch((error) => {
        const err = {
          userMessage: errorMessageFormatter(error.response.data),
          error: error,
        };
        dispatch(
          this.actions.catchError({
            error: err,
            callType: this.callTypes.action,
          })
        );
        return Promise.reject(err);
      });
  };

  getInvoice = (data) => dispatch => {
      dispatch(this.actions.startCall({ callType: this.callTypes.list }))
      return this.requestFromServer
        .getInvoice(data, message => dispatch(this.actions.loadingMessage({ message })))
        .then(res => {
          if (res?.data.status) {
            const entities = res.data.data ? convertDateFormat(res.data.data) : []
            dispatch(this.actions.recordsFetched({ totalCount: 0, entities }))

          } else {
            const err = {
              userMessage: errorMessageFormatter(res.data),
              error: res.data
            }
            dispatch(this.actions.catchError({ error: err, callType: this.callTypes.list }))
            
          }
        })
        .catch(error => {
  
          const err = {
            userMessage: "Can't get data "+ error?.userMessage ,
            error: error
          }
          dispatch(this.actions.catchError({ error: err, callType: this.callTypes.list }))
      
        })
    }



      doSettlement = (data) => dispatch => {
        dispatch(this.actions.startCall({ callType: this.callTypes.action }))
        return this.requestFromServer
          .doSettlement(data, message => dispatch(this.actions.loadingMessage({ message })))
          .then(res => {
    
            if (res?.data.status) {
              dispatch(this.actions.recordCreated({ entity: res.data.data }))
              return Promise.resolve(res.data.data)
            } else {
              const err = {
                userMessage: errorMessageFormatter(res.data),
                error: res.data
              }
              dispatch(this.actions.catchError({ error: err, callType: this.callTypes.action }))
              return Promise.reject(err)
            }
          })
          .catch(error => {
    
            if (!error?.userMessage) {
              const err = {
                userMessage: customServerErrorMessageFormatter(error.response),//errorMessageFormatter(error),
                error: error
              }
              dispatch(this.actions.catchError({ error: err, callType: this.callTypes.action }))
    
              return Promise.reject(err)
            } else {
              return Promise.reject(error)
            }
          })
      }
      

}

export const unpaidMasterCrud = new Crud("invoiceMST/");
export const unpaidMasterSlice = new BaseSlice(
  reducerInfo.name,
  reducerInfo.idFieldName
);
export const unpaidMasterActions = new Actions(
    unpaidMasterCrud,
    unpaidMasterSlice
);
