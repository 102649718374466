import BaseCrud from "../../../_reduxBase/BaseCrud";
import BaseSlice from "../../../_reduxBase/BaseSlice";
import BaseActions from "../../../_reduxBase/BaseActions";
import baseInitialEntity from "../../../_reduxBase/BaseIntialEntity";
import {
  customServerErrorMessageFormatter,
  errorMessageFormatter,
  sortByStandard,
} from "../../../_commons/Utils";
import Axios from "axios";
import moment from "moment";

let todayDate = moment().format("YYYY-MM-DD");
export const reducerInfo = {
  name: "invoiceMaster",
  idFieldName: "id",
  initialEnitity: {
    ...baseInitialEntity,
    organizationMSTId: 0,
    projectMSTId: [],
    fromDate: "",
    toDate: "",
    biilingFrom: "",
    billingFromDate: "",
    billingToDate: "",
    invoiceNumber: "",
    address: "",
    poNo: null,
    id: 0,
    invoiceCreationDates: todayDate,
    invoiceCreationDate: todayDate,
    // InvoiceCreationDate:todayDate ,
    invoiceDueDate: "",
    currencyMSTId: "",
    paymentTerms: "",
    vatGstTax: "",
    expenses: 0,
    invoiceMSTId: null,
    description: "",
  },
};

class Crud extends BaseCrud {
  // getInvoice() {
  //   return Axios.get(
  //     process.env.REACT_APP_BASE_API_URL + "invoiceMST/getInvoiceNumber/"
  //   );
  // }
  getInvoice(data) {
    return Axios.post(`${process.env.REACT_APP_API_URL}invoiceMST/getInvoiceNumber/`, data);
  }

  postToEndpoint(endpoint, data) {
    return Axios.post(`${process.env.REACT_APP_API_URL}${endpoint}`, data);
  }

  postToInvoice(endpoint, data) {
    return Axios.post(
      `${process.env.REACT_APP_API_URL}${endpoint}`,
      data,
      {
        responseType: "arraybuffer",
      }
    );
  }

  postToInvoiceCsv(endpoint, data) {
    return Axios.post(
      `${process.env.REACT_APP_API_URL}${endpoint}`,
      data,
      {
        responseType: "arraybuffer",
      }
    );
  }
}

class Actions extends BaseActions {
  postToServer = (data) => (dispatch) => {
    dispatch(this.actions.startCall({ callType: this.callTypes.action }));

    return this.requestFromServer
      .postToEndpoint("invoiceMST/generateTable/", data)
      .then((res) => {
        if (res?.status === 200) {
          const entity = res.data;
          dispatch(this.actions.recordCreatedSaved({ entity: res.data.data }));
          return Promise.resolve(res.data.data);
        } else {
          const err = {
            userMessage: errorMessageFormatter(res.data),
            error: res.data,
          };
          dispatch(
            this.actions.catchError({
              error: err,
              callType: this.callTypes.action,
            })
          );
          return Promise.reject(err);
        }
      })
      .catch((error) => {
        const err = {
          userMessage: errorMessageFormatter(error.response.data),
          error: error,
        };
        dispatch(
          this.actions.catchError({
            error: err,
            callType: this.callTypes.action,
          })
        );
        return Promise.reject(err);
      });
  };

  previewInvoice = (data) => (dispatch) => {
    dispatch(this.actions.startCall({ callType: this.callTypes.action }));
    return this.requestFromServer
      .postToInvoice("invoiceMST/generatePdf/", data)
      .then((res) => {
        if (res?.status === 200) {
          const contentType = res.headers["content-type"];
          if (contentType === "application/json") {
            const data = JSON.parse(
              new TextDecoder("utf-8").decode(new Uint8Array(res.data))
            );
            const err = {
              userMessage: errorMessageFormatter(data),
              error: data,
            };
            dispatch(
              this.actions.catchError({
                error: err,
                callType: this.callTypes.action,
              })
            );
            return Promise.resolve(data);
          } else if (contentType === "application/octet-stream") {
            // const blob = new Blob([res.data], { type: contentType });
            // const url = URL.createObjectURL(blob);
            dispatch(this.actions.recordCreated(({ entity: res.data.data })))
            return Promise.resolve(res);
          }
        } 
      })
      .catch((error) => {
      const err = {
        userMessage: errorMessageFormatter(error.response.data),
        error: error,
      };
      dispatch(
        this.actions.catchError({
          error: err,
          callType: this.callTypes.action,
        })
      );
      return Promise.reject(err);
    });
  };

  previewCSVInvoice = (data) => (dispatch) => {
    dispatch(this.actions.startCall({ callType: this.callTypes.action }));

    return this.requestFromServer
      .postToInvoiceCsv("invoiceMST/generateCSV/", data)
      .then((res) => {
        const contentType = res.headers["content-type"];
        if (res?.status === 200) {
          if (contentType === "application/json") {
            const data = JSON.parse(
              new TextDecoder("utf-8").decode(new Uint8Array(res.data))
            );
            const err = {
              userMessage: errorMessageFormatter(data),
              error: data,
            };
            
            dispatch(
              this.actions.catchError({
                error: err,
                callType: this.callTypes.action,
              })
            );
            return Promise.resolve();
          } else  { 
            dispatch(this.actions.recordCreated(({ entity: res.data.data })))
            return Promise.resolve(res.data);
          }
        }
      })
      .catch((error) => {
        const err = {
          userMessage: errorMessageFormatter(error.response.data),
          error: error,
        };
        dispatch(
          this.actions.catchError({
            error: err,
            callType: this.callTypes.action,
          })
        );
        return Promise.reject(err);
      });
  };

  getInvoice = (data) => (dispatch) => {
    dispatch(this.actions.startCall({ callType: this.callTypes.list }));
    return this.requestFromServer
      .getInvoice(data)
      .then((res) => {
        if (res?.data?.status) {
          const entities = sortByStandard(res.data.data ?? []);
          return Promise.resolve(res.data.data);
        } else {
          const err = {
            userMessage: errorMessageFormatter(res.data),
            error: res.data,
          };
          dispatch(
            this.actions.catchError({
              error: err,
              callType: this.callTypes.list,
            })
          );
        }
      })
      .catch((error) => {
        const err = {
          userMessage: "Can't get invoice number " ,
          error: error,
        };
        dispatch(
          this.actions.catchError({ error: err, callType: this.callTypes.list })
        );
      });
  };


}

export const dashboardMasterCrud = new Crud("invoiceMST");
export const dashboardMasterSlice = new BaseSlice(
  reducerInfo.name,
  reducerInfo.idFieldName
);
export const dashboardMasterActions = new Actions(
  dashboardMasterCrud,
  dashboardMasterSlice
);
