import BaseCrud from "../../../../_reduxBase/BaseCrud";
import BaseSlice from "../../../../_reduxBase/BaseSlice";
import BaseActions from "../../../../_reduxBase/BaseActions";
import baseInitialEntity from "../../../../_reduxBase/BaseIntialEntity";
import { customServerErrorMessageFormatter, errorMessageFormatter, sortArrayByDate, sortByStandard } from "../../../../_commons/Utils";
import Axios from "axios";
import moment from "moment";



export const reducerInfo = {
  name: "taskMST",
  idFieldName: "id",
  initialEnitity: {
    ...baseInitialEntity,
    organizationMSTId: 0,
    projectMSTId: 0,
    fromDate: "",
    toDate: "",
    biilingFrom: "",
    invoiceNumber: "",
    address: "",
    PO: "",
    id: 0,
    invoiceDueDate: "",
    currencyMSTId: "",
    paymentTerms: "",
    vatGstTax: "SHIFTED",
    expenses: 0,
    "invoiceMSTId": null,
    "description": "",
  },
};

class Crud extends BaseCrud {
  getInvoice(data) {
    return Axios.post(
      process.env.REACT_APP_API_URL + "taskMST/search/", data
    );
  }
}

const sortByPlannedDate = (data) => {

  // const timezone = moment.tz.guess(true)

  const tmpEntities = data.map(x => {
    // let tmpTimezone = timezone
    // if (x?.latitude && x?.longitude) {
    //   tmpTimezone = tz_lookup(x.latitude, x.longitude)
    // }
    // return { ...x, planDateTime: moment.tz(x.planDateTime, "utc").tz(tmpTimezone).format('YYYY-MM-DD HH:mm') }
    return { ...x, schedule: moment.tz(x.schedule, "utc").add(x.utcOffset ?? 0, 'minutes').format('YYYY-MM-DD HH:mm') }
  })
  return sortArrayByDate(tmpEntities, "planDateTime") ?? []

}


class Actions extends BaseActions {
    

  getInvoice = (data) => dispatch => {
      dispatch(this.actions.startCall({ callType: this.callTypes.list }))
      return this.requestFromServer
        .getInvoice(data, message => dispatch(this.actions.loadingMessage({ message })))
        .then(res => {
              const entities = res.data.data ? sortByPlannedDate(res.data.data) : []

          if (res?.data.status) {
            dispatch(this.actions.recordsFetched({ totalCount: 0, entities: entities ?? {} }))

          } else {
            const err = {
              userMessage: errorMessageFormatter(res.data),
              error: res.data
            }
            dispatch(this.actions.recordsFetched({ totalCount: 0, entities: res.data.data ?? {} }))
            dispatch(this.actions.catchError({ error: err, callType: this.callTypes.list }))

            
          }
        })
        .catch(error => {
  
          const err = {
            userMessage: "Can't get data "+ error?.userMessage ,
            error: error
          }
          dispatch(this.actions.catchError({ error: err, callType: this.callTypes.list }))
      
        })
    }



    
      

}

export const TaskSearchMasterCrud = new Crud("TaskMST/");
export const TaskSearchMasterSlice = new BaseSlice(
  reducerInfo.name,
  reducerInfo.idFieldName
);
export const TaskSearchMasterActions = new Actions(
    TaskSearchMasterCrud,
    TaskSearchMasterSlice
);
